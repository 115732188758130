import React, { ReactNode } from 'react'

import * as S from './EndBar.styles'

export const EndBar = ({
  children,
  notice,
  ...rest
}: {
  children?: ReactNode | ReactNode[]
  notice?: ReactNode | ReactNode[]
}) => {
  return (
    <S.Root {...rest}>
      {notice && <S.Notice>{notice}</S.Notice>}
      {children}
    </S.Root>
  )
}
