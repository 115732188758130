import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2),
  boxShadow: theme.shadows[3],
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const Notice = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  marginBottom: theme.spacing(1),

  [theme.breakpoints.up('sm')]: {
    maxWidth: 384,
  },
}))

Notice.defaultProps = {
  variant: 'Lato Caption',
}
